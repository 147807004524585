import "../fetchData/datadog";
import "../locales/index";
import * as React from "react";
import { DefaultSeo } from "next-seo";
import { useUserSlice } from "@/models/useUserSlice";
import { useRouter } from "next/router";
import { Colors, DESKTOP_WIDTH } from "@/constants";
import { useUISlice } from "@/models/useUISlice";
import * as gtag from "../fetchData/gtag";
import * as fbq from "../fetchData/fpixel";
import Head from "next/head";
import Script from "next/script";
import * as serverClient from "@/models/serverClient";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AlertModal from "@/ui/AlertModal";
import AlertPrompt from "@/ui/AlertPrompt";
import AlertTranscriptOptions from "@/ui/AlertTranscriptOptions";
import Paywall from "@/ui/Paywall";
import { getDeviceIdAsync } from "@/models/serverClient";
import AlertDashboardStartTranscription from "@/ui/AlertDashboardStartTranscription";
import AlertDashboardRecorder from "@/ui/AlertDashboardRecorder";
import { Toaster } from "react-hot-toast";
import AlertDashboardViewTranscript from "@/ui/AlertDashboardViewTranscript";

const WEBSITE = "https://1transcribe.com";

export default function MyApp({ Component, pageProps }) {
  const [width, setWidth] = React.useState(0);
  const [deviceId, setDeviceId] = React.useState(
    useUserSlice.getState().deviceId
  );
  const _hydrated = useUserSlice((state) => state._hasHydrated);
  const router = useRouter();

  React.useEffect(() => {
    fbq.pageview();

    const handleRouteChange = (url: string) => {
      fbq.pageview();
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  React.useEffect(() => {
    setWidth(window.innerWidth);

    if (width > 0) {
      const isMobile = window.innerWidth <= DESKTOP_WIDTH - 1;
      useUISlice.getState().setIsMobile(isMobile);
      useUISlice.getState().setWidth(width);
    }
  }, [width]);

  React.useEffect(() => {
    const updateDeviceId = async () => {
      if (deviceId) return;

      const dId = await getDeviceIdAsync();
      setDeviceId(dId);
    };

    updateDeviceId();
  }, [deviceId]);

  React.useEffect(() => {
    if (_hydrated && useUserSlice.getState().userToken) {
      Paywall.getUserAccess(9999).then().catch();

      serverClient
        .getUserAsync()
        .then((userResponse) => {
          if (userResponse && userResponse.data) {
            useUserSlice.getState().setUser(userResponse.data.user);
          }
        })
        .catch();
    }
  }, [_hydrated]);

  const isHidden = !width || !_hydrated || !deviceId;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gtag.GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
            `,
          }}
        />
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                clarity("set", "userId", "${deviceId}");
            })(window, document, "clarity", "script", "nvfdj4rtq0");
            `,
          }}
        /> */}
      </Head>

      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />

      <DefaultSeo
        canonical={WEBSITE}
        title="1Transcribe - Convert unlimited audio and video to text"
        description="1Transcribe is a tool that allows you to convert audio and video to text."
        openGraph={{
          locale: "en_US",
          url: WEBSITE,
          type: "website",
          siteName: WEBSITE.replaceAll("https://", ""),
          title: "1Transcribe - Convert unlimited audio and video to text",
          images: [
            // {
            //   url: `${WEBSITE}/og_banner.jpg`,
            //   width: 1200,
            //   height: 630,
            //   alt: "Website home page image",
            // },
          ],
        }}
      />
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />

      <>
        {!isHidden && (
          <GoogleOAuthProvider
            clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
          >
            <Component {...pageProps} />
            <AlertModal />
            <AlertPrompt />
            <AlertTranscriptOptions />
            <Paywall />
            <AlertDashboardRecorder />
            <AlertDashboardStartTranscription />
            <AlertDashboardViewTranscript />
            <Toaster
              // position={"top-right"}
              toastOptions={{
                style: {
                  fontFamily: "sans-serif",
                  color: Colors.text,
                },
              }}
            />
          </GoogleOAuthProvider>
        )}
      </>
    </>
  );
}
