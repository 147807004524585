import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, ViewStyle, Pressable } from "@/ui/index";
import { Colors } from "@/constants";
import Icon from "@/ui/Icon";

const Checkbox = ({
  value = false,
  containerStyle = {},
  onCheckboxChange,
  color = Colors.subtitle,
  title,
  subtitle,
  disabled = false,
  isRight = false,
}: {
  value?: boolean;
  onCheckboxChange: (active: boolean) => void;
  color?: string;
  containerStyle?: ViewStyle;
  title: string;
  isRight?: boolean;
  disabled?: boolean;
  subtitle?: string;
}) => {
  const [checkBoxActive, setCheckboxActive] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setCheckboxActive(value);
  }, [value]);

  return (
    <Pressable
      disabled={disabled}
      onHoverIn={() => setActive(true)}
      onHoverOut={() => setActive(false)}
      style={StyleSheet.flatten([
        {
          cursor: "pointer",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        },
        containerStyle,
      ])}
      onPress={() => {
        setCheckboxActive(!checkBoxActive);
        onCheckboxChange(!checkBoxActive);
      }}
    >
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: isRight ? "space-between" : "flex-start",
        }}
      >
        {!isRight && (
          <View
            style={{
              ...s.checkbox,
              backgroundColor: checkBoxActive ? Colors.accent : Colors.white,
              borderColor: active
                ? Colors.accent
                : checkBoxActive
                  ? Colors.accent
                  : color,
            }}
          >
            <Icon icon="tick" style={s.checkboxIcon} />
          </View>
        )}

        <View style={{ marginLeft: isRight ? 0 : 8 }}>
          <Text style={{ fontSize: 14, fontWeight: "400", color: Colors.text }}>
            {title}
          </Text>

          {subtitle && (
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{
                  fontSize: 12,
                  lineHeight: 16,
                  color: Colors.subtitle,
                }}
              >
                {subtitle}
              </Text>
            </View>
          )}
        </View>

        {isRight && (
          <View
            style={{
              ...s.checkbox,
              backgroundColor: checkBoxActive ? Colors.accent : Colors.white,
              borderColor: active
                ? Colors.accent
                : checkBoxActive
                  ? Colors.accent
                  : color,
            }}
          >
            <Icon icon="tick" style={s.checkboxIcon} />
          </View>
        )}
      </View>
    </Pressable>
  );
};
const s = StyleSheet.create({
  checkbox: {
    width: 18,
    height: 18,
    borderWidth: 1.5,
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
    borderColor: Colors.subtitle,
  },
  checkboxIcon: {
    tintColor: Colors.white,
    width: 12,
    height: 12,
  },
});

export default Checkbox;
