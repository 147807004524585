import { datadogLogs } from "@datadog/browser-logs";
import * as ServerTypes from "@/api-lib";
import { useUserSlice } from "./useUserSlice";
import Router from "next/router";
import { __DEV__ } from "@/constants";
import { getLocalizedBasePath } from "./utils";

export const getDeviceIdAsync = async () => {
  if (typeof window !== "object") return;

  const deviceId = useUserSlice.getState().deviceId;
  if (deviceId) return deviceId;

  const FingerprintJS = require("@fingerprintjs/fingerprintjs");
  const fp = await FingerprintJS.load();
  const { visitorId } = await fp.get();

  return `web_${visitorId}`;
};

const useAuthorized = (res: Response): any => {
  if (res.status === 403) {
    useUserSlice.getState().reset();
    Router.replace(getLocalizedBasePath());
    return res.json();
  }

  return res.json();
};

export const authenticateAsync = async (
  params: ServerTypes.ApiRequestAuthenticate["payload"]
): Promise<ServerTypes.ApiResponseAuthenticate> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "Authenticate",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => res.json());
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const createTranscriptionAsync = async (
  params: ServerTypes.ApiRequestCreateTranscription["payload"]
): Promise<ServerTypes.ApiResponseCreateTranscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "CreateTranscription",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const updateTranscriptionAsync = async (
  params: ServerTypes.ApiRequestUpdateTranscription["payload"]
): Promise<ServerTypes.ApiResponseUpdateTranscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "UpdateTranscription",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const startTranscriptionAsync = async (
  params: ServerTypes.ApiRequestStartTranscription["payload"]
): Promise<ServerTypes.ApiResponseStartTranscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "StartTranscription",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const listTranscriptionsAsync =
  async (): Promise<ServerTypes.ApiResponseListTranscriptions> => {
    try {
      const requestPayload: ServerTypes.ApiRequest = {
        action: "ListTranscriptions",
      };

      return await fetch(`/api/submit`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: useUserSlice.getState().userToken,
        },
        body: JSON.stringify({ event: requestPayload }),
      }).then((res) => useAuthorized(res));
    } catch (e) {
      __DEV__ && console.log(e);
    }
  };

export const getTranscriptionAsync = async (
  params: ServerTypes.ApiRequestGetTranscription["payload"]
): Promise<ServerTypes.ApiResponseGetTranscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "GetTranscription",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getTranscriptEditAsync = async (
  params: ServerTypes.ApiRequestGetTranscriptEdit["payload"]
): Promise<ServerTypes.ApiResponseGetTranscriptEdit> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "GetTranscriptEdit",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const editTranscriptAsync = async (
  params: ServerTypes.ApiRequestEditTranscript["payload"]
): Promise<ServerTypes.ApiResponseEditTranscript> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "EditTranscript",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const deleteTranscriptionAsync = async (
  params: ServerTypes.ApiRequestDeleteTranscription["payload"]
): Promise<ServerTypes.ApiResponseDeleteTranscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "DeleteTranscription",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getPaymentLinkAsync = async (
  params: ServerTypes.ApiRequestCheckout["payload"]
): Promise<ServerTypes.ApiResponseCheckout> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "Checkout",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getUserAccessAsync = async (
  params: ServerTypes.ApiRequestGetUserAccess["payload"]
): Promise<ServerTypes.ApiResponseGetUserAccess> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "GetUserAccess",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getManageSubscriptionLinkAsync = async (
  params: ServerTypes.ApiRequestGetManageSubscriptionLink["payload"]
): Promise<ServerTypes.ApiResponseGetManageSubscriptionLink> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "GetManageSubscriptionLink",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const sendInquiryAsync = async (
  params: ServerTypes.ApiRequestSendInquiry["payload"]
): Promise<ServerTypes.ApiResponseSendInquiry> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "SendInquiry",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const updateUserAsync = async (
  params: ServerTypes.ApiRequestUpdateUser["payload"]
): Promise<ServerTypes.ApiResponseUpdateUser> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "UpdateUser",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getUserAsync =
  async (): Promise<ServerTypes.ApiResponseGetUser> => {
    try {
      const requestPayload: ServerTypes.ApiRequest = {
        action: "GetUser",
      };

      return await fetch(`/api/submit`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: useUserSlice.getState().userToken,
        },
        body: JSON.stringify({ event: requestPayload }),
      }).then((res) => useAuthorized(res));
    } catch (e) {
      __DEV__ && console.log(e);
    }
  };
