import React from "react";
import { ScrollView, StyleSheet, Text, View } from "@/ui";
import { Colors } from "@/constants";
import Icon from "@/ui/Icon";
import { useTranslation } from "react-i18next";
import DashboardTranscriptionListItem from "@/ui/DashboardTranscriptionListItem";
import { useUserSlice } from "@/models/useUserSlice";
import PrimaryButton from "@/ui/PrimaryButton";
import { useUISlice } from "@/models/useUISlice";
import Menu from "@/ui/Menu";
import { getLangDir } from "@/models/utils";
import { TranscriptExportDocument } from "@/api-lib";

const isRTL = getLangDir() === "rtl";

const DashboardTranscriptPageTitle = ({
  itemId,
  onCopyPress,
  onExportTranscriptPress,
  onDownloadAudioPress,
}: {
  itemId: string;
  onCopyPress: () => void;
  onDownloadAudioPress: () => void;
  onExportTranscriptPress: (params: {
    withSpeakers: boolean;
    withTimestamps: boolean;
    withMonologue: boolean;
    exportType: TranscriptExportDocument;
  }) => void;
}) => {
  const { t } = useTranslation();
  const item = useUserSlice(
    (state) => state.transcriptions.filter((n) => n.id === itemId)[0]
  );
  const isMobile = useUISlice((state) => state.isMobile);

  return (
    <View style={s.container}>
      {isMobile && (
        <View
          style={{
            paddingRight: 16,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text numberOfLines={4} style={s.fileName}>
            {DashboardTranscriptionListItem.formatName(item)}
          </Text>
        </View>
      )}

      <View style={s.metaContainer}>
        <View style={s.dateContainer}>
          <Icon
            icon="calendar"
            containerStyle={s.metaIcon}
            style={s.metaIcon}
          />
          <Text style={s.metaText}>
            {DashboardTranscriptionListItem.formatUploaded(item.createdAt)}
          </Text>
        </View>

        <View style={s.clockContainer}>
          <Icon icon="clock" containerStyle={s.metaIcon} style={s.metaIcon} />
          <Text style={s.metaText}>
            {DashboardTranscriptionListItem.formatDuration(
              item.durationInSeconds
            )}
          </Text>
        </View>
      </View>

      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        style={{
          marginBottom: 16,
          paddingBottom: 8,
          marginHorizontal: -16,
          paddingHorizontal: 16,
        }}
      >
        <PrimaryButton
          style={{ backgroundColor: Colors.hover }}
          textStyle={{ color: Colors.accent }}
          text={t("dashboardTranscriptPageTitle.copyText")}
          onPress={onCopyPress}
        />

        <View style={{ width: 16 }} />

        <PrimaryButton
          style={{ backgroundColor: Colors.hover }}
          textStyle={{ color: Colors.accent }}
          text={t("dashboardTranscriptPageTitle.exportTranscript")}
          onPress={() => {
            useUISlice.getState().setTranscriptOptions({
              hasSpeakers: item.withSpeakerLabels,
              onExport: (options) => {
                onExportTranscriptPress(options);
              },
            });
          }}
        />
        <View style={{ width: 16 }} />

        <PrimaryButton
          style={{ backgroundColor: Colors.hover }}
          textStyle={{ color: Colors.accent }}
          text={t("dashboardTranscriptPageTitle.downloadAudio")}
          onPress={onDownloadAudioPress}
        />

        <View style={{ width: 32 }} />
      </ScrollView>

      <Text
        style={{
          marginBottom: 10,
          color: Colors.gray10,
          fontWeight: "600",
          fontSize: 16,
        }}
      >
        {t("dashboardTranscriptPageTitle.content")}
      </Text>
    </View>
  );
};

const s = StyleSheet.create({
  container: { flex: 1 },
  fileName: {
    fontSize: 24,
    color: Colors.gray10,
    fontWeight: "600",
  },
  metaContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 16,
  },
  dateContainer: { flexDirection: "row", alignItems: "center" },
  metaIcon: { width: 12, height: 12 },
  metaText: {
    marginLeft: 4,
    fontWeight: "300",
    fontSize: 12,
    color: Colors.gray8,
  },
  clockContainer: {
    marginLeft: 16,
    flexDirection: "row",
    alignItems: "center",
  },
});

export default React.memo(DashboardTranscriptPageTitle);
