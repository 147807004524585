import React, { useEffect, useRef, useState } from "react";
import {
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "@/ui/index";
import { Colors } from "@/constants";
import Icon from "@/ui/Icon";

const Picker = ({
  disabled = false,
  onChange,
  label,
  items,
  style = {},
  defaultCurrentIndex = 0,
  position = "top",
}: {
  defaultCurrentIndex?: number;
  onChange: (index: number) => void;
  items: string[];
  label: string;
  disabled?: boolean;
  style?: ViewStyle;
  position?: "top" | "bottom";
}) => {
  const [visible, setVisible] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [currentIndex, setCurrentIndex] = useState(defaultCurrentIndex);
  const pickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const _onItemPress = (index: number) => {
    setCurrentIndex(index);
    onChange(index);
    setVisible(false);
    setHoverIndex(-1);
  };

  return (
    <View style={{ flex: 1, zIndex: 10, ...(style as any) }} ref={pickerRef}>
      <View style={{ flexGrow: 1 }}>
        <Text style={s.label}>{label}</Text>

        <TouchableOpacity
          activeOpacity={0.8}
          onPress={() => setVisible(!visible)}
          style={s.selector}
          disabled={disabled}
        >
          <Text style={s.selectorText}>{items[currentIndex]}</Text>
          <Icon
            icon="caretDown"
            style={{ tintColor: Colors.gray6, width: 16, height: 16 }}
          />
        </TouchableOpacity>
      </View>

      {visible && (
        <ScrollView
          style={{
            ...s.scrollView,
            bottom: position === "top" ? 42 : undefined,
            top: position === "bottom" ? 64 : undefined,
          }}
        >
          {items.map((el, index) => (
            <Pressable
              onPress={() => _onItemPress(index)}
              onHoverIn={() => setHoverIndex(index)}
              onHoverOut={() => setHoverIndex(-1)}
              style={{
                ...s.scrollItem,
                backgroundColor:
                  hoverIndex === index ? Colors.hover : "transparent",
              }}
              key={`${el}_${index}`}
            >
              <Text style={{ fontSize: 14, color: Colors.text }}>{el}</Text>
            </Pressable>
          ))}
        </ScrollView>
      )}
    </View>
  );
};

const s = StyleSheet.create({
  selector: {
    flex: 1,
    minHeight: 40,
    height: 40,
    borderRadius: 2,
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderColor: Colors.borderColor,
    paddingHorizontal: 12,
  },
  selectorText: { fontSize: 14, color: Colors.text },
  label: {
    fontSize: 14,
    fontWeight: "400",
    color: Colors.text,
    marginBottom: 4,
  },
  scrollView: {
    position: "absolute",
    bottom: 42,
    left: 0,
    width: "100%",
    borderRadius: 2,
    backgroundColor: Colors.white,
    borderColor: Colors.borderColor,
    borderWidth: 1,
    zIndex: 10,
    maxHeight: 180,
  },
  scrollItem: {
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderColor,
    justifyContent: "center",
    paddingHorizontal: 8,
  },
});

export default Picker;
